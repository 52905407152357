import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import Vue from "vue";
import { RootState } from "../store";
import { OnBoardingPage } from "../../../utils/models/OnBoardingPage";
import { OnBoardingGoal } from "../../../utils/models/OnBoardingGoal";
import graph_client from "../../../utils/graphql/graph_client";
import graphClient from "../../../utils/graphql/graph_client";
import {GetTrail, GetTrailEncodedPolyline} from "../../../utils/graphql/trail-graph";

import { SignInProvider } from "../../../utils/models/SignInProvider";
import 'firebaseui/dist/firebaseui.css';
import firebase from '../../../js/firebase';
import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

import {
  CreateTrailList,
  GetUser,
  GetUserTrailLists,
  UpdateTrailList,
  UpdateUser
} from "../../../utils/graphql/user-graph";
import axios_client from "../../../utils/helpers/axios_client";
import { CopyCuratedTrailToPtv2PlannedTrail } from "../../../utils/graphql/planned-trail-graph";
import { RevenueCatUtil } from "../../../utils/models/RevenueCatUser";
import { Offering, RevenueCatOffering } from "../../../utils/models/RevenueCatOfferings";
import {RevenueCatWrapper} from "../../../utils/RevenueCatWrapper";

declare global {
  interface Window {
    firebaseConfigs: Object;
    landingPageSlug: String;
  }
}

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export enum AuthMethods {
    Email = 'email',
    Google = 'google',
    Apple = 'apple'
}

export class OnBoardingState {
  pageStack: OnBoardingPage[] = [OnBoardingPage.SignUpOptions]
  onBoardingGoal: OnBoardingGoal | null = null
  slug: string = ''
  regionSlug: string = ''
  countrySlug: string = ''
  trailId: number = 0
  user: firebase.User | null = null
  hiikerUser: any = null
  usersTrailLists: any[] = []
  trail: any = null
  offering: Offering | null = null
  clientSecret: string | null = null
  freeTrialPaymentMethodClientSecret: string | null = null
  paymentMethodClientSecretSubscriptionsUrl: string = window.paymentMethodClientSecretSubscriptionsUrl
  authMethods: AuthMethods[] = [AuthMethods.Email, AuthMethods.Google, AuthMethods.Apple]
}

const actions: ActionTree<OnBoardingState, RootState> = {
  initialiseStore(store) {
    store.commit('setSlugData')
    if (window.id) {
      store.dispatch('getTrail')
    }
  },
  async setupFlowFor(store, goal: OnBoardingGoal) {
    store.commit('setOnBoardingGoal', goal)
    if (window.user == null) {
      store.commit('setPageStack', [OnBoardingPage.SignUpOptions])
    } else {
      store.commit('setUser', window.user)
      switch (store.state.onBoardingGoal) {
        case OnBoardingGoal.DownloadGPX:
          store.commit('setPageStack', [OnBoardingPage.DownloadGPX])
          break
        case OnBoardingGoal.DownloadPrintMaps:
          const revenueCatUtil = await RevenueCatWrapper.isProOrProPlus()
          if (!revenueCatUtil) {
            window.location.href = "/pricing"
          } else {
            store.commit('setPageStack', [OnBoardingPage.DownloadPrintMaps])
          }
          break
        case OnBoardingGoal.AddTrailToList:
          store.commit('setPageStack', [OnBoardingPage.AddTrailToList])
          break
        case OnBoardingGoal.CreateAccount:
          store.commit('setPageStack', [OnBoardingPage.SignUpOptions])
          break
        case OnBoardingGoal.Accommodations:
          store.commit('setPageStack', [OnBoardingPage.Success])
      }
    }
    // @ts-ignore
    $('#on-boarding-dialog').modal('show');
  },
  async setNextPostLoginPage(store) {
    switch (store.state.onBoardingGoal) {
      case OnBoardingGoal.DownloadGPX:
        store.commit('pushPage', OnBoardingPage.DownloadGPX)
        break
      case OnBoardingGoal.DownloadPrintMaps:
        try {
          const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus()
          if (isProOrProPlus) {
            store.commit('pushPage', OnBoardingPage.DownloadPrintMaps)
          } else {
            window.location.href = "/pricing"
          }
        } catch (e) {

        }
        break
      case OnBoardingGoal.AddTrailToList:
        store.commit('pushPage', OnBoardingPage.AddTrailToList)
        return
      case OnBoardingGoal.CreateAccount:
        store.commit('pushPage', OnBoardingPage.Success)
        break
      case OnBoardingGoal.MeasurementTool:
        try {
          const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus()
          if (isProOrProPlus) {
            store.commit('pushPage', OnBoardingPage.Success)
          } else {
            window.location.href = "/pricing"
          }
        } catch (e) {

        }
        break
      case OnBoardingGoal.RedeemVoucher:
        $('#on-boarding-dialog').modal('hide');
        break
      case OnBoardingGoal.CreateAccountForPurchase:
        $('#on-boarding-dialog').modal('hide');
        if (window.purchaseUrl) {
          window.location.href = window.purchaseUrl
        } else {
          alert("You are now successfully logged in.")
        }
        break
      case OnBoardingGoal.CreateAccountForGermany:
        $('#on-boarding-dialog').modal('hide');
        window.location.href = "/de-offer"
        break
      case OnBoardingGoal.CreateAccountForGiftCard:
        $('#on-boarding-dialog').modal('hide');
        if (window.giftCardUrl) {
          window.location.href = window.giftCardUrl
        } else {
          alert("You are now successfully logged in.")
        }
        break
      default:
        store.commit('pushPage', OnBoardingPage.Success)
    }
  },
  async downloadPrintMaps(store) {
    let headers = {}
    try {
      const appCheckTokenResponse = await firebase.appCheck().getToken(false);
      headers = { 'X-Firebase-AppCheck': appCheckTokenResponse.token }
    } catch (e) {
      console.error(e);
    }
    let response = await graphClient.request(GetTrail,
      { id: store.state.trailId }, headers,
    )
    const printMapUrl = response.trail.defaultPrintMap.url
    window.open(printMapUrl, '_blank');
    store.commit('pushPage', OnBoardingPage.Success)
  },
  downloadGPX(store) {
    setTimeout(() => {
      store.commit('pushPage', OnBoardingPage.Success)
    }, 2000)
    // @ts-ignore
    window.location = `${store.getters.trailUrl}/gpx_file`
  },
  downloadKML(store) {
    setTimeout(() => {
      store.commit('pushPage', OnBoardingPage.Success)
    }, 2000)
    // @ts-ignore
    window.location = `${store.getters.trailUrl}/kml_file`
  },
  downloadGeoJSON(store) {
    setTimeout(() => {
      store.commit('pushPage', OnBoardingPage.Success)
    }, 2000)
    // @ts-ignore
    window.location = `${store.getters.trailUrl}/geojson_file`
  },
  async getUserTrailLists(store) {
    try {
      const response = await graphClient.request(GetUserTrailLists)
      store.commit('setUsersTrailLists', response.userTrailLists)
    } catch (e) {
      alert(e)
    }
  },
  async createTrailList(store, name: string) {
    try {
      await graphClient.request(CreateTrailList, { trailList: { name, trailIds: [store.state.trailId] } })
      await store.dispatch('getUserTrailLists')
    } catch (e) {
      alert(e)
    }
  },
  async updateTrailList(store, trailList: any) {
    try {
      const trailListIds = trailList.trails.map((trail: any) => trail.id)
      const trailToToggle = store.state.trailId
      if (trailListIds.includes(`${trailToToggle}`)) {
        trailListIds.splice(trailListIds.indexOf(`${trailToToggle}`), 1)
      } else {
        trailListIds.push(trailToToggle)
      }
      await graphClient.request(UpdateTrailList, { trailList: { id: trailList.id, trailIds: trailListIds.map(id => parseInt(id)) } })
      await store.dispatch('getUserTrailLists')
    } catch (e) {
      alert(e)
    }
  },
  async signUpWithEmail(store, details: { email: string, password: string, fullName: string, displayName: string, }) {
    try {
      const userResponse = await firebase.auth().createUserWithEmailAndPassword(details.email, details.password)
      if (userResponse.user) {
        store.commit('setUser', userResponse.user)
        await store.dispatch('sendAccessToken')
        await graph_client.request(UpdateUser, { user: { title: details.fullName, displayName: details.displayName, deOfferUnlock: window.de_offer_unlock } })
        store.commit('pushPage', OnBoardingPage.ConfirmDetails)
        window.bus.$emit('user:updated')
      }
    } catch (e) {
      alert(e)
    }
  },
  async signInWith(store, provider: SignInProvider) {
    let authProvider;
    switch (provider) {
      case SignInProvider.Google:
        authProvider = new GoogleAuthProvider();
        break
      case SignInProvider.Facebook:
        authProvider = new FacebookAuthProvider();
        break
      case SignInProvider.Apple:
        authProvider = new firebase.auth.OAuthProvider('apple.com');
        authProvider.addScope('email');
        authProvider.addScope('name');
        break
      case SignInProvider.Email:
        return store.commit('pushPage', OnBoardingPage.EmailSignUp)
    }
    try {
      const signInResponse = await firebase.auth().signInWithPopup(authProvider)
      const user = signInResponse.user
      if (user) {
        store.commit('setUser', user)
        await store.dispatch('sendAccessToken')
        store.commit('pushPage', OnBoardingPage.ConfirmDetails)
        await graphClient.request(GetUser, { firebaseID: user.uid })
        window.bus.$emit('user:updated')
      }
    } catch (e) {
      // @ts-ignore
      alert(e.message)
    }
  },
  async loginWithEmail(store, details: { email: string, password: string }) {
    try {
      const userResponse = await firebase.auth().signInWithEmailAndPassword(details.email, details.password)
      if (userResponse.user) {
        store.commit('setUser', userResponse.user)
        await store.dispatch('sendAccessToken')
        store.commit('pushPage', OnBoardingPage.ConfirmDetails)
        window.bus.$emit('user:updated')
      } else {
        alert("Error logging in")
      }
    } catch (e: any) {
      alert(e.message)
    }
  },
  async sendAccessToken(store) {
    try {
      if (store.state.user) {
        const token = await store.state.user.getIdToken()
        const redirectUrl = `/firebase/auth.json?accessToken=${token}`;
        graphClient.setHeader('Authorization', token);
        await axios_client.get(redirectUrl)
      } else {
        throw Error("No user found")
      }
    } catch (e) {
      // @ts-ignore
      alert(e.message)
    }
  },
  async updateUser(store, userDetails: { fullName: string, displayName: string }) {
    const { fullName, displayName } = userDetails;

    // Retrieve fbclid from the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');

    // Construct the payload for the GraphQL request
    const userPayload = {
      title: fullName,
      deOfferUnlock: window.de_offer_unlock,
      displayName,
      ...(fbclid && {
        fbclid,
        fbcltime: new Date(performance.timeOrigin).toISOString() // Use timeOrigin instead of navigationStart
      }),
    };

    // Send the mutation request
    await graph_client.request(UpdateUser, { user: userPayload });

    // Proceed with the rest of the logic
    await store.dispatch('setNextPostLoginPage');
  },
  async updateUserForFb(store) {

    // Retrieve fbclid from the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');
    if (!fbclid || !window.user) {
      return
    }

    // Construct the payload for the GraphQL request
    const userPayload = {
      ...(fbclid && {
        fbclid,
        fbcltime: new Date(performance.timeOrigin).toISOString() // Use timeOrigin instead of navigationStart
      }),
    };

    // Send the mutation request
    await graph_client.request(UpdateUser, { user: userPayload });
  },
  detailsConfirmed(store) {
    store.dispatch('setNextPostLoginPage')
  },
  async getHiikerUser(store) {
    if (store.state.user) {
      const hiikerUser = await graphClient.request(GetUser, { firebaseID: store.state.user.uid })
      if (hiikerUser) {
        store.commit('setHiikerUser', hiikerUser.user)
      }
    } else {
      throw Error("No user found")
    }
  },
  async getTrail(store) {
    try {
      let headers = {}
      try {
        const appCheckTokenResponse = await firebase.appCheck().getToken(false);
        headers = { 'X-Firebase-AppCheck': appCheckTokenResponse.token }
      } catch (e) {
        console.error(e);
      }
      let trailRequest = await graphClient.request(GetTrail,
        { id: store.state.trailId }, headers,
      )
      store.commit('setTrail', trailRequest.trail)
    } catch (e) {
      // console.log(e)
      // error
      // alert(e)
    }
  },
  async copyCuratedTrailToPlannedTrails(store) {
    try {
      await graphClient.request(CopyCuratedTrailToPtv2PlannedTrail, { trail: { id: store.state.trailId } })
    } catch (e) {
      alert(e)
    }
  },
  async getClientSecret(store) {
    if (store.state.offering == null) {
      throw Error("No price found")
    }

    let response = await axios_client.post('/subscriptions.json', { price_id: store.state.offering.price.id, landing_page_slug: window.landingPageSlug })
    await store.commit('setClientSecret', response.data.client_secret)
  },
  async getCheckoutPage(store) {
    if (store.state.offering == null) {
      throw Error("No offering found")
    }
    // @ts-ignore
    window.location = `/purchases/start_checkout_session?price_id=${store.state.offering.price.id}&success_url=${window.location.origin + window.location.pathname}`
  },
  async retrieveFreeTrialPaymentMethodClientSecret(store) {
    try {
      const response = await axios_client.post(store.state.paymentMethodClientSecretSubscriptionsUrl)
      store.commit('setFreeTrialPaymentMethodClientSecret', response.data.client_secret)
    } catch (e) {
      // @ts-ignore
      alert(e.message)
    }
  },
  resetPassword(store, email: string) {
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      alert("Password reset email sent")
    }).catch((error) => {
      alert(error)
    });
  }
}

const mutations: MutationTree<OnBoardingState> = {
  setAuthMethods(state, authMethods: AuthMethods[]) {
    state.authMethods = authMethods
  },
  setFreeTrialPaymentMethodClientSecret(state, clientSecret: string) {
    state.freeTrialPaymentMethodClientSecret = clientSecret
  },
  setClientSecret(store, secret: string) {
    store.clientSecret = secret
  },
  setTrail(state, trail) {
    state.trail = trail
  },
  setUsersTrailLists(state, trailLists: any[]) {
    state.usersTrailLists = trailLists
  },
  setHiikerUser(state, hiikerUser) {
    state.hiikerUser = hiikerUser
  },
  setUser(state, user: firebase.User) {
    state.user = user
  },
  setSlugData(state) {
    state.slug = window.slug;
    state.regionSlug = window.region_slug;
    state.countrySlug = window.country_slug;
    state.trailId = window.id
  },
  setPageStack(state, pageStack: OnBoardingPage[]) {
    state.pageStack = pageStack
  },
  pushPage(state, page: OnBoardingPage) {
    state.pageStack.push(page)
  },
  popPage(state) {
    state.pageStack.pop()
  },
  setOnBoardingGoal(state, goal: OnBoardingGoal) {
    state.onBoardingGoal = goal
  },
  setOffering(state, offering: Offering) {
    state.offering = offering
  }
}

const getters: GetterTree<OnBoardingState, RootState> = {
  lastPage(store) {
    return store.pageStack[store.pageStack.length - 1]
  },
  trailUrl(store) {
    return `/trails/${store.countrySlug}/${store.regionSlug}/${store.slug}`
  },
}

const state = Vue.observable(new OnBoardingState());

export default class OnBoardingModule implements Module<OnBoardingState, RootState> {
  namespaced = true
  state = state
  actions = actions
  mutations = mutations
  getters = getters
}
