<template>
  <div>
    <h3 class="mb-4"><strong>Download GPX for Trail name</strong></h3>

    <div class="form-group mb-3">
      <p><label class="m-0 text-yellow">Choose file type:</label></p>
      <p class="p-2 bg-dark w-100 rounded">
        <select class="custom-select w-100" v-model="selectedOption">
          <option selected value="gpx">GPX</option>
          <option value="kml">KML</option>
          <option value="geojson">GeoJSON</option>
        </select>
      </p>
    </div>

    <button class="btn btn-primary" @click="downloadGPXClicked">Download</button>
    <LoadingDialog :show-loading-dialog="loading" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import LoadingDialog from '../../utils/shared_components/LoadingDialog.vue';

export default defineComponent({
  name: 'OnBoardingDownloadGPXPage',
  data() {
    return {
      selectedOption: 'gpx',
      downloadUrl: `${this.$store.getters['onBoarding/trailUrl']}/gpx_file`,
      loading: false,
    };
  },
  watch: {
    selectedOption() {
      switch (this.selectedOption) {
        case 'gpx':
          this.downloadUrl = `${this.$store.getters['onBoarding/trailUrl']}/gpx_file`;
          break;
        case 'kml':
          this.downloadUrl = `${this.$store.getters['onBoarding/trailUrl']}/kml_file`;
          break;
        case 'geojson':
          this.downloadUrl = `${this.$store.getters['onBoarding/trailUrl']}/geojson_file`;
          break;
        default:
          throw new Error('Invalid option');
      }
    },
  },
  methods: {
    popTopPage() {
      this.$store.commit('onBoarding/popPage');
    },
    async downloadGPXClicked() {
      this.loading = true;
      switch (this.selectedOption) {
        case 'gpx':
          await this.$store.dispatch('onBoarding/downloadGPX');
          break;
        case 'kml':
          await this.$store.dispatch('onBoarding/downloadKML');
          break;
        case 'geojson':
          await this.$store.dispatch('onBoarding/downloadGeoJSON');
          break;
        default:
          break;
      }
    },
  },
  components: {
    LoadingDialog,
  },
  mounted() {

  },
});
</script>

<style scoped>

</style>
