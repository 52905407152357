<template>
  <div>
    <h3 class="mb-4"><strong>Download printable PDF map</strong></h3>

    <template v-if="trail">
      <h3>{{ trail.name }} <span class="material-icons mr-2 float-right">download</span></h3>
    </template>
    <hr>
    <button class="btn btn-primary" :disabled="loading" @click="downloadPrintMapsTapped">
      <template v-if="loading">
        <i class="fa fa-spinner fa-spin"></i> Loading...
      </template>
      <template v-else>
        Download
      </template>
    </button>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    trail() {
      return this.$store.state.onBoarding.trail;
    },
  },
  methods: {
    async downloadPrintMapsTapped() {
      try {
        this.loading = true;
        await this.$store.dispatch('onBoarding/downloadPrintMaps');
        this.loading = false;
      } catch (e) {
        alert(e);
        this.loading = false;
      }
    },
    popTopPage() {
      this.$store.commit('onBoarding/popPage');
    },
  },
});
</script>

<style scoped>

</style>
