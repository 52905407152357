<template>
<div>
  <form>

    <h3 class="mb-4"><strong>Confirm your details:</strong></h3>

    <div class="form-group mb-3">
      <label class="m-0" for="exampleInputEmail1">Email address</label>
      <input type="email" readonly class="form-control form-control-plaintext" id="email" aria-describedby="emailHelp" placeholder="example@email.com" v-model="email">
    </div>
    <div class="form-group mb-3">
      <label for="name">Full Name</label>
      <input type="text" class="form-control" id="name" placeholder="Joe Blogs" v-model="fullName">
    </div>
    <div class="form-group mb-3">
      <label for="display-name">Display Name</label>
      <input type="text" class="form-control" id="display-name" placeholder="joey-hikes" v-model="displayName">
    </div>
    <button @click="confirmDetails" class="btn btn-primary mb-2">Confirm</button>
  </form>
  <LoadingDialog :show-loading-dialog="loading" />
</div>
</template>

<script lang="ts">

import LoadingDialog from "../../utils/shared_components/LoadingDialog.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OnBoardingConfirmDetailsPage',
  data() {
    return {
      email: '',
      displayName: '',
      fullName: '',
      loading: true
    };
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch('onBoarding/getHiikerUser');
    const { hiikerUser } = this.$store.state.onBoarding;
    if (hiikerUser) {
      this.email = hiikerUser.email;
      this.displayName = hiikerUser.displayName;
      this.fullName = hiikerUser.title;
    } else {
      this.email = this.$store.state.onBoarding.user.email;
      this.fullName = this.$store.state.onBoarding.user.displayName;
    }
    this.loading = false
  },
  methods: {
    async confirmDetails(event: Event) {
      this.loading = true
      event.preventDefault()
      await this.$store.dispatch('onBoarding/updateUser', {
        fullName: this.fullName,
        displayName: this.displayName,
      });
      this.loading = false
    },
  },
  components: {
    LoadingDialog,
  },
});
</script>

<style scoped>

.form-control:disabled, .form-control[readonly] {
  background-color: transparent !important;
}

</style>
