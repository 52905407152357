import { gql } from 'graphql-request';

export const GetTrail = gql`
  query GetTrail($id:ID!) {
    trail(id:$id) {
      lineString {
        geoData
        id
        lineStringableId
        lineStringableType
        createdAt
        updatedAt
      }
      multilinestring
      activityTags {
        name
        id
      }
      featureTags {
        name
        id
      }
      allPossibleActivityTags
      allPossibleFeatureTags
      id
      name
      trailDistance
      durationString
      shortAddress
      averageRating
      numberOfRatings
      currentDescription
      countryId
      createdAt
      updatedAt
      slug
      totalElevation
      totalElevationLoss
      elavationPerKm
      maxElevation
      lonlat
      difficulty
      publishedAt
      keywords
      staticMapImage
      numberOfDays
      altsCount
      spursCount
      busStopCount
      fastfoodCount
      healthcareCount
      historyCount
      peakCount
      pharmacyCount
      postOfficeCount
      pubCount
      restaurantCount
      shelterCount
      shopCount
      townCount
      transportStationCount
      viewpointCount
      waterCount
      warningCount
      waypointCount
      campgroundCount
      osmcampsiteCount
      motelCount
      bnbCount
      hostelCount
      hotelCount

      caveCount
      coffeeCount
      picnicCount
      evChargingCount
      parkingCount
      restroomCount
      playgroundCount
      storageCount
      swimmingCount
      wifiCount
      wildlifeCount
      waterfallCount
      warningCount
      informationCount
      gateCount
      junctionCount

      stagesCount
      jumboImage {
        url
        metadata
      }
      stagesCount
      searchBoost
      impressionsCount
      durationMinutes
      tagList
      staticMapImage
      linkedBlogCount
      countrySlug
      regionSlug
      mobileStaticMapImage
      reviews {
        id
        userId
        trailId
        body
        rating
        createdAt
        updatedAt
        ranking
        publicRecording {
          shareableUrl
        }
        photos {
          lgUrl
          smUrl
          mdUrl
          metadata
          id
          recordType
          recordId
          processed
        }
        firebaseReviewId
        likes {
          id
          likeableType
          likeableId
          user {
            firebaseUserId
          }
        }
        user {
          firebaseUserId
          avatar {
            id
            lgUrl
            smUrl
            mdUrl
            metadata
          }
          title
          id
        }
      }
    }
  }
`;

export const GetTrailExplore = gql`
  query GetTrail($id:ID!) {
    trail(id:$id) {
      featureCollection
      id
      name
      totalElevation
      lineString {
        geoData
      }
      difficulty
      averageRating
      durationMinutes
      photos {
        url
      }
      staticMapImage
      mobileStaticMapImage
      createdAt
      countrySlug
      regionSlug
      slug
      trailDistance
    }
  }
`;

export const GetTrailLineString = gql`
  query GetTrail($id:ID!) {
    trail(id:$id) {
      featureCollection
      id
    }
  }
`;

export const GetTrailMap = gql`
  query GetTrail($id:ID!) {
    trail(id:$id) {
      lineString {
        geoData
        id
        lineStringableId
        lineStringableType
        createdAt
        updatedAt
      }
      id
      name
      multilinestring
      trailDistance
      durationString
      shortAddress
      averageRating
      numberOfRatings
      currentDescription
      countryId
      createdAt
      updatedAt
      slug
      totalElevation
      totalElevationLoss
      elavationPerKm
      maxElevation
      lonlat
      difficulty
      publishedAt
      keywords
      staticMapImage
      numberOfDays
      altsCount
      spursCount
      busStopCount
      fastfoodCount
      healthcareCount
      historyCount
      peakCount
      pharmacyCount
      postOfficeCount
      pubCount
      restaurantCount
      shelterCount
      shopCount
      townCount
      transportStationCount
      viewpointCount
      waterCount
      warningCount
      waypointCount
      campgroundCount
      osmcampsiteCount
      motelCount
      bnbCount
      hostelCount
      hotelCount

      caveCount
      coffeeCount
      picnicCount
      evChargingCount
      parkingCount
      restroomCount
      playgroundCount
      storageCount
      swimmingCount
      wifiCount
      wildlifeCount
      waterfallCount
      warningCount
      informationCount
      gateCount
      junctionCount

      stagesCount
      jumboImage {
        url
        metadata
      }
      stagesCount
      searchBoost
      impressionsCount
      durationMinutes
      tagList
      staticMapImage
      linkedBlogCount
      countrySlug
      regionSlug
    }
  }
`;

export const GetTrailReviews = gql`
  query GetTrail($id:ID!) {
    trail(id:$id) {
      id
      name
      slug
      reviews {
        id
        userId
        trailId
        body
        rating
        createdAt
        updatedAt
        ranking
        photos {
          lgUrl
          smUrl
          mdUrl
          metadata
          id
          recordType
          recordId
        }
        firebaseReviewId
        likes {
          id
          likeableType
          likeableId
          user {
            firebaseUserId
          }
        }
        user {
          firebaseUserId
          avatar {
            id
            lgUrl
            smUrl
            mdUrl
            metadata
          }
          title
          id
        }
      }
    }
  }
`;

export const GetTrailTags = gql`
  query TagsByType {
    tags(taggableType:"Trail") {
      id
      name
    }
  }
`;

export const GetTrailEncodedPolyline = gql`
  query GetTrailEncodedPolyline($id:ID!) {
    trail(id:$id) {
      id
      name
      slug
      lineString {
        encodedPolyline
        id
        lineStringableId
        lineStringableType
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_TRAIL = gql`
  mutation UpdateTrail($trailId: ID!, $activityTags: [String!], $featureTags: [String!]) {
    updateTrail(
      input: {
        id: $trailId
        activityTags: $activityTags
        featureTags: $featureTags
      }
    ) {
      trail {
        id
        activityTags {
          name
        }
        featureTags {
          name
        }
      }
    }
  }
`;
